<template>
  <ErrorMessage
    v-bind="$attrs"
    class="formElementError"
    as="div"
  />
</template>

<script>
import { ErrorMessage } from 'vee-validate';

export default {
  components: {
    ErrorMessage,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.formElementError {
  @include label;
  margin: rem(5px) 0 0 0;
  color: $color-error-dark;
}
</style>